<template>
  <div
    :class="[
      $vuetify.theme.dark
        ? 'dark-mode login-page-dark login-wrapper'
        : 'login-page login-wrapper',
    ]"
  >
    <div
      :class="[$vuetify.theme.dark ? 'background-circle-dark' : 'background-circle']"
    />
    <v-dialog v-model="showCredentialsSentModal" max-width="400">
      <v-card>
        <v-card-title style="display: flex; justify-content: center">
          <img src="/src/assets/illustrations/success-light.svg" />
        </v-card-title>
        <h1 style="text-align: center">Success</h1>
        <v-card-text>
          Credentials sent! Please check your email for your student's login details.
          Don't forget to check your Spam/Junk folder.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <primary-button @click="showCredentialsSentModal = false"
            >Okay
          </primary-button>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MOBILE BAR -->
    <MobileBar />

    <!-- MAIN CONTAINER -->
    <PageLayout class="login-wrapper">
      <v-card class="card-default login-box">
        <!-- <div class="d-flex justify-center my-4"> -->
        <img
          class="img my-4"
          alt="Logo"
          :src="
            $img(`icons/${$vuetify.theme.dark ? 'light' : 'blue'}Horizontal.svg`)
          "
        />

        <!-- LOGIN FORM -->
        <v-form
          v-if="!showForgotPassword"
          v-model="isLoginFormValid"
          ref="loginForm"
          class="login-form"
          @submit.prevent="turnstileLoadFunction"
        >
          <h1 class="big-text mb-4">Let's Start Learning!</h1>
          <div v-if="scheduledMaintenance && backendOffline">
            <v-alert color="primary" dark>
              🚧 Hey there! 🚧<br /><br />
              Our website is currently in the garage for a quick tune-up. We’re
              working hard to make it even better for you. We’ll be back online soon,
              so please check back later. Thanks for your patience!
              <br /><br />
              <span>- CodeWizardsHQ</span>
            </v-alert>
          </div>
          <TextField
            v-model.trim="credentials.username"
            :rules="[required]"
            outlined
            autocomplete="off"
            label="Username"
            class="mb-2"
          />
          <TextField
            v-model.trim="credentials.password"
            :rules="[
              required,
              (v) => (!!v && v.length >= 3) || 'That password isn\'t long enough',
            ]"
            outlined
            autocomplete="off"
            :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPin = !showPin"
            :type="showPin ? 'text' : 'password'"
            label="Password"
          />
          <div id="turnstile-box" style="text-align: center"></div>
          <div class="actions d-flex flex-column align-center">
            <PrimaryButton
              :loading="isLoading.login"
              type="submit"
              style="width: 100%; height: 48px"
              >Login
            </PrimaryButton>
            <v-btn
              text
              @click="showForgotPassword = true"
              style="margin-top: 10px"
              class="forgot-btn"
              >Forgot password?
            </v-btn>
          </div>
        </v-form>

        <!-- PASSWORD RECOVERY -->
        <div v-if="showForgotPassword" class="form-container">
          <BackButton
            class="back-btn"
            @click="showForgotPassword = !showForgotPassword"
            >Login
          </BackButton>

          <h1 class="big-text">Recovery Password</h1>
          <p>
            Login credentials are always sent upon enrollment from
            <strong>Natalie at CodeWizardsHQ</strong>
          </p>

          <p>
            Please check your spam/junk folder for your original Welcome Email, if
            you still can't find it, use the form below to have your credentials
            resent to you
          </p>
          <v-form @submit.prevent="requestPasswordRecovery">
            <TextField v-model.trim="parentEmail" outlined label="Parent Email" />
            <div class="actions d-flex">
              <v-spacer />
              <PrimaryButton
                type="submit"
                :loading="isLoading.credentialRecovery"
                class="full-width-btn"
                style="width: 100%; height: 48px"
              >
                Submit
              </PrimaryButton>
              <v-spacer />
            </div>
          </v-form>
          <p>
            <strong class="emergency-heading">Emergency Information: </strong>If you
            aren't able to login, please visit out
            <a class="blue-link" href="https://codewizardshq.com/emergency/"
              >emergency page</a
            >
            for help and contact information
          </p>
        </div>

        <!-- USEFUL LINKS -->
        <Links
          :is-parent-login="isParentLogin"
          @toggle-login="isParentLogin = !isParentLogin"
        />
      </v-card>

      <LoginReferralCard />
    </PageLayout>
  </div>
</template>

<script>
import Links from '@/components/Login/Links.vue'
import MobileBar from '@/components/Login/MobileBar.vue'
import PageLayout from '../shared/PageLayout.vue'
import { bootIntercom } from '@/utils/helperFunctions'
import * as Sentry from '@sentry/vue'
import { mapActions, mapWritableState } from 'pinia'
import { useAccountStore } from '@/stores/accountStore'
import { useChallengeStore } from '@/stores/challengeStore'
import { useNotificationsStore } from '@/stores/notificationsStore'

export default {
  name: 'Login',
  components: { Links, MobileBar, PageLayout },
  data() {
    return {
      showPin: false,
      isLoading: { login: false, credentialRecovery: false },
      showForgotPassword: false,
      showCredentialsSentModal: false,
      isLoginFormValid: false,
      isParentLogin: this.$route.query.p === 'true',
      credentials: { username: null, password: null },
      parentEmail: null,
      siteKey: import.meta.env.VITE_CAPTCHA_SITE_KEY,
      scheduledMaintenance: import.meta.env.VITE_MAINTENANCE_MODE === '1',
    }
  },
  computed: {
    ...mapWritableState(useAccountStore, [
      'account',
      'currentClasses',
      'plannedClasses',
      'selectedClassId',
      'nextClassStartDate',
      'refreshKey',
      'backendOffline',
      'hideNavigation',
    ]),
  },
  mounted() {
    const params = new URLSearchParams(location.search)
    for (const entry of params.entries()) {
      if (entry[0] === 'forgotPassword') {
        this.showForgotPassword = true
        this.parentEmail = entry[1]
        this.requestPasswordRecovery()
      }
    }

    // Load Turnstile
    const script = document.createElement('script')
    script.src =
      'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit'
    script.setAttribute('defer', '')
    document.head.appendChild(script)
  },
  methods: {
    ...mapActions(useAccountStore, ['getAccount']),
    ...mapActions(useChallengeStore, ['fetchGlobalChallengeData']),
    ...mapActions(useNotificationsStore, ['beginNotificationsInterval']),
    requestPasswordRecovery() {
      this.isLoading.credentialRecovery = true
      this.$http
        .post(`/auth/forgotpassword/${this.parentEmail}`)
        .then(() => {
          this.showCredentialsSentModal = true
        })
        .catch((e) => {
          alert(e.response.data)
        })
        .finally(() => (this.isLoading.credentialRecovery = false))
    },
    turnstileLoadFunction() {
      this.$refs.loginForm.validate()
      if (this.credentials.username.includes('@')) {
        return this.$alert('Your email is not your username', 'error')
      }
      if (!this.isLoginFormValid) return

      this.isLoading.login = true
      window.turnstile.render('#turnstile-box', {
        sitekey: this.siteKey,
        theme: this.$vuetify.theme.dark ? 'dark' : 'light',
        action: 'editor-login',
        callback: this.login,
        'error-callback': () => (this.isLoading.login = false),
        cData: 'username=' + this.credentials.username,
      })
    },
    required: (v) => !!v || 'This field is required',
    login(turnstileToken) {
      this.isLoading.login = true

      // get the current route
      // todo: save this in the store and change the route to /login

      // hack to remove legacy /edit in the URL
      let thePath = window.location.pathname
      if (thePath.startsWith('/edit') && !thePath.startsWith('/editor'))
        thePath = thePath.replace('/edit', '')
      this.$http
        .post('/auth/login/learn', { ...this.credentials, token: turnstileToken })
        .then(async (res) => {
          this.account = res.data.account
          this.nextClassStartDate = res.data.nextClassStartDate

          this.currentClasses = res.data.currentClasses
          this.plannedClasses = res.data.plannedClasses
          this.hideNavigation = res.data.hideNavigation

          if (res.data.currentClasses.length > 0) {
            this.selectedClassId = res.data.currentClasses[0].id
          }

          // if (res.data.currentClass) {
          //   // TODO: this will come from back end eventually
          //   // this.currentClass = res.data.currentClass
          //   this.currentClasses = [res.data.currentClass]
          //   this.selectedClassId = res.data.currentClass.classId
          // } else {
          //   this.currentClass = { classId: null }
          // }

          bootIntercom(res.data.account)
          Sentry.setUser({
            email: res.data.account.parent1email,
            username: res.data.account.username,
            id: res.data.account.id,
          })

          await this.fetchGlobalChallengeData()
          this.beginNotificationsInterval()

          if (this.hideNavigation && this.$route.path !== '/placement') {
            this.$router.push('/placement')
          }

          // hack to refresh component state if route doesn't change
          else if (this.$route.path === '/' && thePath === '/') {
            this.$router.push('/dashboard')
          } else if (thePath !== this.$route.path) {
            this.$router.push(thePath)
          } else {
            this.refreshKey++
          }
        })
        .catch((e) => {
          console.error('Login error: ', e)
          if (e.response.status === 500) {
            return this.$alert('Server error', 'error', true, 5 * 1000)
          }

          this.$alert(e.response.data.detail, 'error', true, 5 * 1000)
        })
        .finally(() => (this.isLoading.login = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  position: relative;
  overflow: hidden;
  background: #fff5dc;
}

.background-circle {
  position: absolute;
  border-radius: 100%;
  background: white;
  height: 200vh;
  width: 200vh;
  right: -45%;
  top: -45%;
  /* -webkit-mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%);
  mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%); */
}

.login-page-dark {
  position: relative;
  overflow: hidden;
  background: #272b30;

  ::v-deep .v-icon.mdi {
    color: white !important;
  }
}

.background-circle-dark {
  position: absolute;
  border-radius: 100%;
  background: #111315;
  height: 200vh;
  width: 200vh;
  right: -45%;
  top: -45%;
  /* -webkit-mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%);
  mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%); */
}

.big-text {
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: var(--v-text-primary);
  margin-bottom: 8px;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 60px;
}

.login-box {
  /* margin: auto; */
  width: 588px;
  max-width: 588px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .login-form {
    width: 428px;
  }

  .forgot-btn {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    text-decoration: underline;
    color: var(--v-text-base);
  }

  .img {
    margin: 0 auto;
    max-width: 204px;
    border-radius: unset !important;
  }
}

.login-wrapper {
  @media screen and (max-width: 1020px) {
    flex-direction: column;
    height: unset;
    padding-bottom: 60px;

    .login-box {
      margin: 20px auto;
      min-height: unset;
    }
  }
}

.form-container {
  padding: 0 22px;

  .back-btn {
    position: absolute;
    left: 16px;
    top: 20px;
  }
}

.actions {
  margin: 12px 0;
}
</style>
