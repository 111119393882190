import cusAddProject from '@/components/customIcons/cusAddProject.vue'
import cusAttendanceAbsent from '@/components/customIcons/cusAttendanceAbsent.vue'
import cusAttendancePresent from '@/components/customIcons/cusAttendancePresent.vue'
import cusBooks from '@/components/customIcons/cusBooks.vue'
import cusCalendar from '@/components/customIcons/cusCalendar.vue'
import cusChevronLeft from '@/components/customIcons/cusChevronLeft.vue'
import cusClosedCircle from '@/components/customIcons/cusClosedCircle.vue'
import cusDownload from '@/components/customIcons/cusDownload.vue'
import cusLock from '@/components/customIcons/cusLock.vue'
import cusGreenCheck from '@/components/customIcons/cusGreenCheck.vue'
import cusMagnify from '@/components/customIcons/cusMagnify.vue'
import cusMegaphone from '@/components/customIcons/cusMegaphone.vue'
import cusNewFile from '@/components/customIcons/cusNewFile.vue'
import cusNewFolder from '@/components/customIcons/cusNewFolder.vue'
import cusRedo from '@/components/customIcons/cusRedo.vue'
import cusSubmit from '@/components/customIcons/cusSubmit.vue'
import cusDelete from '@/components/customIcons/cusDelete.vue'
import cusExperience from '@/components/customIcons/cusExperience.vue'
import cusLevelBadge from '@/components/customIcons/cusLevelBadge.vue'
import cusGoldCoin from '@/components/customIcons/cusGoldCoin.vue'
import cusOpenExternal from '@/components/customIcons/cusOpenExternal.vue'
import cusPreview from '@/components/customIcons/cusPreview.vue'
import cusRedX from '@/components/customIcons/cusRedX.vue'
import cusRefresh from '@/components/customIcons/cusRefresh.vue'
import cusRename from '@/components/customIcons/cusRename.vue'
import cusSave from '@/components/customIcons/cusSave.vue'
import cusServers from '@/components/customIcons/cusServers.vue'
import cusSettings from '@/components/customIcons/cusSettings.vue'
import cusStarFull from '@/components/customIcons/cusStarFull.vue'
import cusStarOutline from '@/components/customIcons/cusStarOutline.vue'
import cusStarShield from '@/components/customIcons/cusStarShield.vue'
import cusUndo from '@/components/customIcons/cusUndo.vue'
import cusUndoClock from '@/components/customIcons/cusUndoClock.vue'
import cusUploadFile from '@/components/customIcons/cusUploadFile.vue'
import cusWand from '@/components/customIcons/cusWand.vue'

import body from '@/components/customIcons/avatar/body.vue'
import clothing from '@/components/customIcons/avatar/clothing.vue'
import face from '@/components/customIcons/avatar/face.vue'
import hair from '@/components/customIcons/avatar/hair.vue'
import hat from '@/components/customIcons/avatar/hat.vue'
import neck from '@/components/customIcons/avatar/neck.vue'

export default {
  values: {
    cusAddProject: { component: cusAddProject },
    cusAttendanceAbsent: { component: cusAttendanceAbsent },
    cusAttendancePresent: { component: cusAttendancePresent },
    cusBooks: { component: cusBooks },
    cusCalendar: { component: cusCalendar },
    cusChevronLeft: { component: cusChevronLeft },
    cusClosedCircle: { component: cusClosedCircle },
    cusDownload: { component: cusDownload },
    cusLock: { component: cusLock },
    cusRedo: { component: cusRedo },
    cusSubmit: { component: cusSubmit },
    cusDelete: { component: cusDelete },
    cusExperience: { component: cusExperience },
    cusGoldCoin: { component: cusGoldCoin },
    cusGreenCheck: { component: cusGreenCheck },
    cusLevelBadge: { component: cusLevelBadge },
    cusMagnify: { component: cusMagnify },
    cusMegaphone: { component: cusMegaphone },
    cusNewFile: { component: cusNewFile },
    cusNewFolder: { component: cusNewFolder },
    cusOpenExternal: { component: cusOpenExternal },
    cusPreview: { component: cusPreview },
    cusRedX: { component: cusRedX },
    cusRefresh: { component: cusRefresh },
    cusRename: { component: cusRename },
    cusSave: { component: cusSave },
    cusServers: { component: cusServers },
    cusSettings: { component: cusSettings },
    cusStarFull: { component: cusStarFull },
    cusStarOutline: { component: cusStarOutline },
    cusStarShield: { component: cusStarShield },
    cusUndo: { component: cusUndo },
    cusUndoClock: { component: cusUndoClock },
    cusUploadFile: { component: cusUploadFile },
    cusWand: { component: cusWand },
    // AVATAR EDITOR
    body: { component: body },
    clothing: { component: clothing },
    face: { component: face },
    hair: { component: hair },
    hat: { component: hat },
    neck: { component: neck },
  },
}
