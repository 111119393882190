<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" class="wrapper">
    <div class="bottom-row">
      <v-btn
        icon
        large
        target="_blank"
        href="https://www.facebook.com/codewizardshq"
      >
        <v-icon color="black">mdi-facebook</v-icon>
      </v-btn>
      <v-btn
        icon
        large
        target="_blank"
        href="https://www.instagram.com/codewizardshq/"
      >
        <v-icon color="black">mdi-instagram</v-icon>
      </v-btn>
      <v-btn icon large target="_blank" href="https://twitter.com/codewizardshq/">
        <v-icon color="black">mdi-twitter</v-icon>
      </v-btn>
    </div>

    <div class="top-row">
      <!-- <v-btn text target="_blank" href="https://codewizardshq.com/students/"
        >Student Help Center</v-btn
      > -->
      <v-btn text target="_blank" href="https://codewizardshq.com/emergency/"
        >Emergency page</v-btn
      >
    </div>

    <!-- <div class="middle-row">
      <v-btn text @click="$emit('toggle-login')"
        >{{ isParentLogin ? 'Student' : 'Parent' }} Login</v-btn
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Links',
  props: { isParentLogin: { type: Boolean, required: true } },
}
</script>

<style lang="scss" scoped>
$text-color: #303030;

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;

  .top-row,
  .middle-row,
  .bottom-row {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
}
</style>
