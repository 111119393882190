import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import calendar from 'dayjs/plugin/calendar'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(calendar)
dayjs.extend(advancedFormat)

const tz = dayjs.tz.guess()
dayjs.tz.setDefault(tz)

// export functions for one off use case
// export const fromNow = (ts: string) => dayjs(ts).fromNow()
export const formattedDate = (date: string) => dayjs.utc(date).toDate()
export const formatExact = (ts: string) =>
  dayjs.utc(ts).local().format('dddd MMMM D, h:mm A z')
export const calTime = (ts: string) => dayjs.utc(ts).local().calendar()
export const lastSubmitTime = (ts: string) => dayjs(ts).format('M/D/YYYY h:mma z')
export const simpleDate = (ts: string) => dayjs(ts).format('M/D/YYYY')
export const isPast = (ts: string) => dayjs(ts).isBefore(dayjs())
export const now = () => dayjs()
export const dayAndTime = (ts: string) => dayjs(ts).format('MM/DD/YY [at] hh:mm a')

export const finalSubmitTime = dayjs().add(2, 'days').add(2, 'hours').toISOString()
/**Converts a string to Dayjs object */
export const toDayjs = (ts: string) => dayjs(ts)

export function timeSince(date: string) {
  const time = (Number(dayjs().toDate()) - Number(dayjs.utc(date).toDate())) / 1000
  const seconds = Math.floor(time)

  let interval = Math.floor(seconds / 31536000)
  if (interval > 1) return `${interval} years ago`

  interval = Math.floor(seconds / 2592000)
  if (interval > 1) return `${interval} months ago`

  interval = Math.floor(seconds / 86400)
  if (interval >= 1) return `${interval} days ago`

  interval = Math.floor(seconds / 3600)
  if (interval >= 1) return `${interval} hours ago`

  interval = Math.floor(seconds / 60)
  if (interval >= 1) return `${interval} minutes ago`

  return `${Math.floor(seconds)} seconds ago`
}

export default dayjs
