import { toDayjs } from '@/utils/dayjs'
import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import { defineStore, acceptHMRUpdate, storeToRefs } from 'pinia'
import { defineAsyncComponent } from 'vue'
import LeaderLine, { DashOptions, Options } from 'leader-line-new'
import challengeData from '@/utils/ajax/challengeData'
import { useAccountStore } from './accountStore'
import { mixpanel } from '@/utils/helperFunctions'

dayjs.extend(duration)

export const useChallengeStore = defineStore('challenge', {
  state: () => ({
    ageGroup: null as AgeGroup,
    /**The current viewable challenge question (with full data) that is loaded. */
    selectedQuestion: null as Question,

    /**List of past challenge questions available for selection. */
    recentChallenges: [] as QuestionBase[],

    /**The selection data (not full data) for the currently active question (of the week). */
    activeQuestion: null as Question,

    /**Final ISO/UTC time that submission of the activeQuestion is allowed. */
    finalSubmitTime: null as Dayjs,

    /**Dayjs Duration object that represents time between now() and finalSubmitTime. */
    timerDuration: null as Duration,

    isLoading: null as boolean,

    /**True if we should disable challenges (e.g., user 'grandfathered' isn't set) */
    disableChallenges: false,

    //
    // STREAK
    //
    streak: {
      streak: null as number,
      streakGoal: null as number,
      xpReward: null as number,
      currencyReward: null as number,
    },
    answerStreak: {
      streak: null as number,
      streakGoal: null as number,
      xpReward: null as number,
      currencyReward: null as number,
    },
    // currentStreak: null as number,
    // currentStreakGoal: null as number,
    // streakCurrencyReward: null as number,
    // streakXpReward: null as number,

    //
    // MATCHING QUESTION
    //
    /**Object representation of each LeaderLine between left and right images */
    lineObjs: [] as LeaderLineObj[],
    /**IDs of the left and right image elements, if selected. */
    currElementIds: { left: null as string, right: null as string },
    /**LeaderLine instance from target to mouse while selecting. */
    drawLine: null as LeaderLine,

    //
    // DRAG AND DROP QUESTION
    //
    dragQuestionIsComplete: false,

    submitModal: {
      isShowing: false,
      isCorrectAnswer: null as boolean,
    },
  }),
  getters: {
    getChallengeStatus(state) {
      if (!state.activeQuestion) return 'noActiveChallenge'
      else if (state.activeQuestion.isCorrect) return 'complete'
      else if (state.activeQuestion.attemptsRemaining === 0) return 'outOfAttempts'
      else if (state.activeQuestion.isExpired) return 'incomplete'
      else return 'inProgress'
    },
    getActiveAgeGroup(state) {
      if (!state.ageGroup) return 'N/A'
      return state.ageGroup
    },
    /**Returns the async question component based on question type. */
    questionComponent(state) {
      let src: any
      switch (state.selectedQuestion?.type) {
        case 'DRAG_N_DROP':
          src = () =>
            import(`../components/Challenges/QuestionTypes/DragAndDropQ.vue`)
          return defineAsyncComponent(src)
        case 'MULTIPLE_CHOICE':
          src = () =>
            import(`../components/Challenges/QuestionTypes/MultipleChoiceQ.vue`)
          return defineAsyncComponent(src)
        case 'MATCHING':
          src = () => import(`../components/Challenges/QuestionTypes/MatchingQ.vue`)
          return defineAsyncComponent(src)
        case 'MULTIPLE_ANSWER':
          src = () =>
            import(`../components/Challenges/QuestionTypes/MultipleAnswers.vue`)
          return defineAsyncComponent(src)
        default:
          return ''
      }
    },
    /**Returns true if the submit button for challenge should be disabled. */
    disableSubmit(state) {
      const question = state.selectedQuestion
      if (!question) return false
      if (question.type === 'MATCHING') {
        const answerLength = state.lineObjs.length
        const requiredLength = question.prompt.imagesLeft.length
        if (answerLength < requiredLength) return true
        return false
      }

      if (question.type === 'MULTIPLE_ANSWER') {
        for (const entry of question.prompt.multiAnswerEntries) {
          if (!question.answer[entry.id]) {
            return true
          }
        }
        return false
      }

      if (question.type === 'DRAG_N_DROP') {
        return !state.dragQuestionIsComplete
      }

      if (question.type === 'MULTIPLE_CHOICE') {
        if (!question.answer) return true
        return false
      }
    },
    getRecentQuestion: (state) => {
      return (challId: number) =>
        state.recentChallenges.find((c) => c.id === challId)
    },
  },
  actions: {
    async initForTesting(id) {
      return challengeData.getChallengeForTesting(id).then((res) => {
        this.removeLinesFromDOM()
        this.selectedQuestion = res.data
        this.activeQuestion = res.data
        this.isLoading = false
        this.selectedQuestion.isExpired = false
        return res.data
      })
    },
    async init() {
      this.isLoading = true

      const getQs = challengeData.getRecentChallengeQuestions().then(({ data }) => {
        this.recentChallenges = data
      })

      await Promise.allSettled([getQs, this.fetchGlobalChallengeData()])

      // if active question, select it
      // otherwise, fetch more deatil on first recent question
      if (this.activeQuestion?.id) {
        this.selectedQuestion = this.activeQuestion
        mixpanel('Challenge Viewed', {
          challengeId: this.activeQuestion.id,
          ageGroup: this.ageGroup,
        })
      } else if (this.recentChallenges.length > 0) {
        this.fetchQuestion(this.recentChallenges[0].id)
      }

      this.reSyncInThirtyMinutes()
      this.isLoading = false
    },
    fetchGlobalChallengeData() {
      this.isLoading = true
      return challengeData.getChallengeGlobalInfo().then(({ data }) => {
        if (data.disableChallenges) {
          this.disableChallenges = data.disableChallenges
          this.isLoading = false
          return
        }
        this.streak = data.streak
        this.ageGroup = data.ageGroup
        // this.currentStreak = data.streak.streak
        // this.currentStreakGoal = data.streak.streakGoal
        // this.streakCurrencyReward = data.streak.currencyReward
        // this.streakXpReward = data.streak.xpReward

        if (data.activeChallenge) {
          this.finalSubmitTime = toDayjs(data.activeChallenge.expiration)
          this.activeQuestion = data.activeChallenge
          this.updateTime()
        }

        this.isLoading = false
      })
    },
    reSyncInThirtyMinutes() {
      const THIRTY_MINUTES = 1000 * 60 * 30
      setTimeout(() => {
        challengeData
          .getChallengeGlobalInfo()
          .then(({ data }) => {
            if (data.activeChallenge) {
              this.finalSubmitTime = toDayjs(data.activeChallenge.expiration)
            }
          })
          .finally(() => {
            this.reSyncInThirtyMinutes()
          })
      }, THIRTY_MINUTES)
    },
    getAnswersToSend() {
      const question = this.selectedQuestion
      const type = question.type
      let toSend: any

      switch (type) {
        case 'DRAG_N_DROP':
        case 'MULTIPLE_CHOICE':
        case 'MULTIPLE_ANSWER':
          toSend = this.selectedQuestion.answer
          break
        case 'MATCHING':
          toSend = []
          this.lineObjs.forEach((lineObj) => {
            toSend.push({
              leftImage: lineObj.leftId,
              rightImage: lineObj.rightId,
            })
          })
          break
        default:
          console.log('error: type mismatch in submitChallenge()')
      }
      return toSend
    },
    async submitTestAnswers() {
      const question = this.selectedQuestion
      let toSend = this.getAnswersToSend()

      return challengeData
        .postChallengeTestingAnswer(question.id, toSend)
        .then((res) => {
          console.log('challenge test res is: ', res)
          if (res.data) {
            alert('Question is CORRECT')
          } else {
            alert('INCORRECT answer')
          }
        })
    },
    async submitChallenge() {
      this.isLoading = true
      const question = this.selectedQuestion
      const { selectedClassId } = storeToRefs(useAccountStore())
      let toSend = this.getAnswersToSend()

      mixpanel('Challenge Attempted', {
        challengeId: question.id,
        ageGroup: this.ageGroup,
      })
      return challengeData
        .postChallengeAnswer(question.id, toSend, selectedClassId.value)
        .then(({ data }) => {
          this.streak = data.streak
          this.answerStreak = data.answerStreak
          // this.currentStreak = data.streak.streak
          // this.currentStreakGoal = data.streak.streakGoal
          // this.streakCurrencyReward = data.streak.currencyReward
          // this.streakXpReward = data.streak.xpReward
          this.finalSubmitTime = toDayjs(data.activeChallenge.expiration)
          this.activeQuestion = data.activeChallenge
          this.selectedQuestion = data.activeChallenge
          this.resetStudentAnswers()

          // display the modal
          this.submitModal.isCorrectAnswer = data.isCorrect
          this.submitModal.isShowing = true

          if (data.isCorrect) {
            mixpanel('Challenge Completed', {
              challengeId: question.id,
              ageGroup: this.ageGroup,
            })
          }
        })
        .catch(() => {
          alert('There was an error submitting the answer for this challenge.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchQuestion(id?: number) {
      this.isLoading = true
      const { selectedClassId } = storeToRefs(useAccountStore())
      return challengeData
        .getChallengeQuestion(id, selectedClassId.value)
        .then((res) => {
          this.removeLinesFromDOM()
          this.selectedQuestion = res.data
          this.isLoading = false

          mixpanel('Challenge Viewed', { challengeId: id, ageGroup: this.ageGroup })
          return res.data
        })
    },
    /**Used to reset student answers to the active question when it's selected/viewed. */
    resetStudentAnswers() {
      const { type } = this.selectedQuestion
      if (type === 'MATCHING') return this.resetLines()
      if (type === 'DRAG_N_DROP') return this.resetDragAndDropQ()
      if (type === 'MULTIPLE_ANSWER') return this.resetMultiAnswers()
      if (type === 'MULTIPLE_CHOICE') return (this.activeQuestion.answer = {})
    },
    resetMultiAnswers() {
      this.selectedQuestion.answer = {}
    },
    //
    // MATHCING QUESTION
    //
    /**Resets any selected images, completed lines, and line to mouse, if applicable. */
    resetLines() {
      this.lineObjs.forEach((lineObj) => lineObj.instance.remove())
      this.lineObjs = []
      this.currElementIds = { left: null, right: null }
      if (this.drawLine) {
        this.drawLine.remove()
        this.drawLine = null
      }
    },
    async resetDragAndDropQ() {
      const parent = document.getElementById('img-parent')
      const targets = Array.from(
        document.getElementsByClassName('target-div')
      ) as HTMLDivElement[]
      const els = Array.from(
        document.getElementsByClassName('drag-img')
      ) as HTMLImageElement[]
      for (const el of els) {
        parent.appendChild(el)
        el.style.position = 'absolute'
      }
      for (const el of targets) {
        el.style.border = '2px dashed #ffc844'
        el.style.animationPlayState = 'running'
      }
      this.selectedQuestion.answer = {}
      this.dragQuestionIsComplete = false
    },
    /**Draws a line using saved ids -- e.g., used to recreate lines when navigating back to active question. */
    drawLinesFromLineObjs() {
      this.lineObjs.forEach((lineObj) => {
        lineObj.instance = this.createLeaderLine(lineObj.leftId, lineObj.rightId)
      })
    },
    removeLinesFromDOM() {
      this.lineObjs.forEach((lineObj) => lineObj.instance.remove())
      this.lineObjs = []
    },
    /**Draws a leader line on the DOM. */
    createLeaderLine(leftId: string, rightId: string) {
      const leftElement = document.getElementById(`left${leftId}`)
      const rightElement = document.getElementById(`right${rightId}`)

      if (!leftElement || !rightElement) return
      const dash: DashOptions = { animation: true }
      const options: Options = { startSocket: 'right', endSocket: 'left', dash }
      const instance = new LeaderLine(leftElement, rightElement, options)
      this.lineObjs.push({ instance, leftId, rightId })
      return instance
    },
    updateTime() {
      if (!this.finalSubmitTime) return this.checkTimeAter30Seconds()

      // challenge has timed out -- fetch new data
      if (this.finalSubmitTime.isBefore(dayjs())) {
        this.init()
        return
      }
      this.timerDuration = dayjs.duration(this.finalSubmitTime.diff(dayjs()))
      setTimeout(() => {
        this.updateTime()
      }, 1000)
    },
    /**Waits 30 seconds before checking for timer time again. */
    checkTimeAter30Seconds() {
      setTimeout(() => {
        this.updateTime()
      }, 30 * 1000)
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChallengeStore, import.meta.hot))
}

// types
export type LeaderLineObj = { instance: LeaderLine; leftId: string; rightId: string }
export type DragTarget = { id: number; x: number; y: number }
export interface DragImage {
  filename: string
  key: string
  id: number
  x: number // supply this on the front end
  y: number
}

export type QuestionBase = {
  id: number
  title: string

  /**The UTC timestamp of the expiration of the challenge. */
  expiration: string

  /**The UTC timestamp of the time the user completed, if any. */
  dateCompleted?: string

  /**User's reward in currency upon successfull completion. */
  currencyReward: number

  /**User's reward in experience upon successfull completion. */
  xpReward?: number

  /**Attempts the user has for the question - 0 if the question has timed out. */
  attemptsRemaining: 0 | 1 | 2 | 3
}

export type Question = QuestionBase & {
  type: 'DRAG_N_DROP' | 'MULTIPLE_CHOICE' | 'MATCHING' | 'MULTIPLE_ANSWER'

  /**Body text of the question. */
  body: string

  /**Text explanation of the question's correct answer. */
  explanation?: string

  /** If the user was correct, or null if they haven't answered. */
  isCorrect?: boolean

  /**True if the challenge is expired, or if the active challenge has been answered. */
  isExpired?: boolean

  /**True if the timer should be shown. */
  showTimer?: boolean

  /**Data that is specific to the question type. */
  prompt: {
    // drag and drop
    targets?: DragTarget[]
    shape?: 'SQUARE' | 'CIRCLE'
    size?: number
    backgroundImage?: string
    images?: DragImage[]

    // matching
    imagesLeft?: string[]
    imagesRight?: string[]

    // multi choice
    isImage?: boolean
    choices?: Answer[]

    // multiple answers
    multiAnswerEntries?: MultiAnswerEntry[]
    multiAnswerUnit?: string
  }

  /**The student answer, if any. */
  answer: any
}

export type MatchObj = { id: number; leftImage: string; rightImage: string }

export type Answer = { id: number; text: string }

export type ChallengeRes = Question
export type RecentChallengesRes = QuestionBase[]
export type ChallengeNavigationRes = {
  streak: {
    streak: number
    streakGoal: number
    xpReward: number
    currencyReward: number
  }
  activeChallenge: Question
  streakExtraReward: number
  disableChallenges: boolean
  ageGroup?: AgeGroup
}

export type AgeGroup = 'E' | 'M' | 'H'
export type MultiAnswerEntry = { id: number; label: string }
