<template>
  <v-app :class="['app', $vuetify.theme.dark ? 'dark-mode' : '']">
    <!-- SPLASH SCREEN -->
    <v-overlay v-if="state.isLoading.initial">
      <img src="/src/assets/images/dashboard/blueHorizontal.svg" />
      <LoadingSpinner size="300" />
      <br /><br />
      <div style="text-align: center">Loading...</div>
    </v-overlay>

    <Login v-else-if="!accountStore.account || !accountStore.account.id" />

    <template v-else>
      <!-- <WelcomeProgramModal v-if="welcomeProgramModal.show" /> -->
      <BirthdayModal />
      <RefreshPageModal />
      <NavBar v-if="!hideNavigation" />
      <!-- <WorldMap /> -->
      <Notices v-if="$route.name !== 'Editor'" />
      <v-main>
        <!-- <LoadingSpinner v-if="isLoadingRoute" size="300" /> -->
        <!-- <router-view :key="refreshKey" v-else-if="account.id" /> -->
        <router-view :key="accountStore.refreshKey" />
        <BrowserWarning />
      </v-main>
    </template>
    <Alert />
  </v-app>
</template>

<style src="./style/style.css"></style>

<script setup lang="ts">
import Alert from '@/components/Alert.vue'
import BirthdayModal from '@/components/BirthdayModal.vue'
import BrowserWarning from '@/components/BrowserWarning.vue'
// import Feedback from '@/components/StudentFeedback/Feedback.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import Login from '@/components/Login/Login.vue'
import NavBar from '@/components/NavBar/NavBar.vue'
import Notices from '@/components/Notices.vue'
import RefreshPageModal from '@/components/RefreshPageModal.vue'
import { app } from '@/main' // TODO: how else to connect to $vuetify?

import { useEditorStore } from '@/stores/editorStore'
import { useAccountStore } from '@/stores/accountStore'
import { useNotificationsStore } from '@/stores/notificationsStore'
import { provide, reactive } from 'vue'
import { mixpanel } from '@/utils/helperFunctions'
import { useRoute, useRouter } from 'vue-router/composables'
import { axiosKey, vuetifyKey } from '@/utils/injectionKeys'
import client from './utils/ajax/http'
import { useChallengeStore } from './stores/challengeStore'
import { storeToRefs } from 'pinia'

const router = useRouter()
const route = useRoute()
const state = reactive({ isLoading: { initial: false } })

// TODO: move this when in vue 3
provide(vuetifyKey, app.$vuetify)
provide(axiosKey, client)
const vuetify = app.$vuetify

const accountStore = useAccountStore()
const { hideNavigation } = storeToRefs(accountStore)
const challengeStore = useChallengeStore()
const editorStore = useEditorStore()
const notificationsStore = useNotificationsStore()

mixpanel('Learn App Loaded')

init()
function init() {
  challengeStore.fetchGlobalChallengeData()
  state.isLoading.initial = true
  // handle ctrl S saving
  document.onkeydown = (e) => {
    if (e.keyCode == 83 && e.ctrlKey) {
      editorStore.saveCurrent()
      return false
    }
  }

  // fetch on load
  // TODO: getAccount should fire on route load if account.id is null
  accountStore
    .getAccount()
    .then((res) => {
      state.isLoading.initial = false

      if (accountStore.account && accountStore.account.id) {
        notificationsStore.beginNotificationsInterval()
      }

      if (hideNavigation.value && route.path !== '/placement') {
        router.push('/placement')
      } else if (res === 'helpScreen') router.push('/help')
    })
    .catch(() => accountStore.setAccountEmpty())
    .finally(() => (state.isLoading.initial = false))

  // handle dark theme
  if (localStorage.darkTheme) {
    vuetify.theme.dark = localStorage.getItem('darkTheme') === 'true'
  } else {
    localStorage.setItem('darkTheme', vuetify.theme.dark.toString())
  }

  const darkTheme = vuetify.theme.dark

  // hack to fix white bottom of page when dark mode is active
  const bodyNode = document.querySelector('body')
  if (darkTheme) {
    bodyNode.style.background = '#111315'
  } else {
    bodyNode.style.background = 'white'
  }

  // handle code font size
  if (localStorage.codeFontSize) editorStore.codeFontSize = localStorage.codeFontSize
}

function determineStartupModal(promiseResults) {
  // const isEligible = promiseResults[1].data.eligible
  // const isFirstLogin = promiseResults[1].data.first_login
  // priority order is:
  // welcome program > birthday modal > survey reminder
  // welcome program if they are eligible to do welcome program and it's first login
  // const doWelcomeProgram = isEligible && isFirstLogin
  // if (doWelcomeProgram) {
  //   this.welcomePrograModal.show = true
  //   return
  // }
  // should we display missing birthday info reminder?
  // const doBirthday =
  //   !this.account.studentbirthday || this.account.studentbirthday === ''
  // if (doBirthday) {
  // this.SET_SHOW_BIRTHDAY_MODAL(true)
  // return
  // }
  // should we display student feedback reminder?
  // const doFeedbackReminder =
  //   notificationsStore.notifications.some(
  //     (notification) => notification.surveyId && notification.readStatus === 0
  //   ) &&
  //   accountStore.selectedClass.id &&
  //   !accountStore.selectedClass.disableFeedback
  // if (doFeedbackReminder) {
  //   modalStore.surveyReminderModal.show = true
  //   return
  // }
}
</script>

<style lang="scss">
/* fix an odd bug with vuetify rows in updated sass */
.row {
  margin: unset !important;
}

@import './scss/darkMode.scss';
</style>
