<template>
  <div v-if="$vuetify.breakpoint.smAndDown" class="menu-wrapper">
    <div class="mobile-logo">
      <img
        id="logo-mobile"
        class="logo"
        alt="Logo"
        src="/src/assets/icons/blueHorizontal.svg"
      />
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mobile-menu">
          <v-icon :class="$vuetify.theme.dark ? 'dark' : ''"
            >mdi-dots-vertical</v-icon
          >
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          class="link-btn"
          target="_blank"
          href="https://codewizardshq.com/students/"
          >Student Help Center</v-list-item
        >
        <v-list-item
          class="link-btn"
          target="_blank"
          href="https://codewizardshq.com/emergency/"
          >Emergency Page</v-list-item
        >
        <v-list-item
          class="link-btn"
          target="_blank"
          href="https://username.codewizardshq.com/edit/?p&_ga=2.54027883.218469501.1603863876-706607137.1594284095"
          >Parent Login</v-list-item
        >
        <v-list-item
          class="link-btn"
          target="_blank"
          href="https://www.facebook.com/codewizardshq"
        >
          <i class="mdi mdi-facebook"></i><span>Facebook</span>
        </v-list-item>
        <v-list-item
          class="link-btn"
          target="_blank"
          href="https://twitter.com/codewizardshq/"
        >
          <i class="mdi mdi-twitter"></i><span>Twitter</span>
        </v-list-item>
        <v-list-item
          class="link-btn"
          target="_blank"
          href="https://www.instagram.com/codewizardshq/"
        >
          <i class="mdi mdi-instagram"></i><span>Instagram</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
$border-color: #dddcdc;
$text-color: #303030;
$form-color: #ffffff;

.menu-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: $form-color;
  border-bottom: 1px solid $border-color;

  .mobile-logo {
    .logo {
      max-width: 160px;
      min-width: 100px;
    }
  }
}

.mobile-menu {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
}

.menu-bar {
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px #00000014, 0 3px 14px 2px #00000012,
    0 5px 5px 3px #00000020;
  position: absolute;
  top: 18px;
  right: 12px;
  background-color: $form-color;
  display: none;
  opacity: 0;
  transition: all 0.5s ease;
}
.dark {
  color: black !important;
}
.menu-bar a {
  display: block;
  font-size: 16px;
  padding: 16px;
  border-bottom: 1px solid $border-color;
  text-decoration: none;
  font-weight: 600;
}
.menu-bar i {
  margin-right: 8px;
}
</style>
