<template>
  <div class="nav-bar">
    <v-dialog v-model="surveyReminderModal.show" persistent max-width="400">
      <SuccessInfoErrorCard
        title="Hey!"
        message="There are <strong>100 wizard points</strong> waiting for you! You can earn them by completing this feedback survey. Your opinion is valuable to us, so please complete them as soon as you can!"
        imgSrc="feedback/survey-todo.svg"
        classString=""
        styleString="max-width: 300px; height: auto"
        primary-btn-text="Okay, let's do it!"
        secondary-btn-text="Maybe later..."
        @close="surveyReminderModal.show = false"
        @ok="handleClickCompleteSurvey"
      />
    </v-dialog>

    <v-dialog v-model="showMeetingErrorCard" max-width="400">
      <SuccessInfoErrorCard
        title="Hey!"
        :message="[
          'We have detected that a popup blocker may be interfering with your ability to join a class',
          'If this is the case, please paste the following url into a new tab to open the class meeting:',
          `${classURL}`,
          'If this issue persists, please check your popup settings, switch to Firefox/Chrome/Edge, or contact us for further assistance.',
        ]"
        primary-btn-text="Close"
        @ok="showMeetingErrorCard = false"
      />
    </v-dialog>

    <v-dialog v-model="joinClassModal" persistent width="500">
      <v-card>
        <v-list>
          <v-list-item>
            <v-card-title>Which class would you like to join?</v-card-title>

            <v-spacer></v-spacer>

            <v-icon right @click="joinClassModal = false">mdi-close</v-icon>
          </v-list-item>

          <v-list-item v-for="cls in zoomClasses" :key="cls.id" class="ml-4">
            <p class="mr-3">{{ cls.name }}</p>
            <PrimaryButton
              :href="cls.zoom_url"
              target="_blank"
              small
              class="mb-4"
              @click="joinClassModal = false"
              >Join
            </PrimaryButton>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- Mobile toolbar -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-icon>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isLoading.zoomMeeting"
            :elevation="0"
            class="primary-btn"
            @click.prevent="gotomeeting"
          >
            <v-icon color="black" left small>mdi-briefcase-outline</v-icon>
            <span>Join Class</span>
          </v-btn>
        </v-list-item>
        <v-divider class="mt-1 mb-4"></v-divider>

        <template v-for="(tab, index) in tabs">
          <v-list-group
            v-model="isTabOpen[index]"
            v-if="tab.nested"
            :key="tab.screen"
            active-class="header-md-tabs"
            append-icon="mdi-menu-down"
          >
            <!-- active-class="text-primary" -->
            <template v-slot:activator>
              <v-list-item-title
                @click="!isTabOpen[index] && mixpanelTopLevel(tab.text)"
                class="font-weight-moderate color--text text--darken-3"
              >
                {{ tab.text }}
              </v-list-item-title>
            </template>
            <template v-for="n in tab.nested">
              <v-list-item
                v-if="n.screen && n.show !== false"
                :key="n.s"
                :to="{ name: n.screen }"
                @click="mixpanelMenuSections(n.text, tab.text)"
              >
                <v-list-item-title
                  class="ml-3 text-decoration-none font-weight-moderate color--text text--darken-3"
                >
                  {{ n.text }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="n.url && n.show !== false"
                :key="n.s"
                :href="n.url"
                @click="mixpanelMenuSections(n.text, tab.text)"
                class="text-decoration-none"
                target="_blank"
              >
                <v-list-item-title
                  class="ml-3 font-weight-moderate color--text text--darken-3"
                >
                  {{ n.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            v-else-if="tab.url"
            :key="tab.screen"
            :href="tab.url"
            @click="mixpanelTopLevel(tab.text)"
            active-class="header-md-tabs"
            class="text-decoration-none"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-body-1 font-weight-moderate color--text text--darken-3"
                v-text="tab.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-else
            :key="tab.screen"
            @click="mixpanelTopLevel(tab.text)"
            :dense="true"
            :to="{ name: tab.screen }"
            active-class="header-md-tabs"
            class="text-decoration-none"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-body-1 font-weight-moderate color--text text--darken-3"
                v-text="tab.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider class="mb-2"></v-divider>
        <v-menu min-width="200px" top>
          <template v-slot:activator="{ on, attr }">
            <v-list-item v-bind="attr" v-on="on">
              <v-list-item-avatar>
                <v-img :src="account.avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ account.studentfirstname }}
                  {{ account.studentlastname }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ account.title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'Settings' }" class="text-decoration-none">
              <v-list-item-title
                class="font-weight-moderate color--text text--darken-3"
              >
                Parent Dashboard
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              class="text-decoration-none"
              @click="showChangeAvatarDialog = true"
            >
              <v-list-item-title
                class="font-weight-moderate color--text text--darken-3"
              >
                Change Avatar
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="darkTheme" @change="switchDarkTheme" />
              </v-list-item-action>
              <v-list-item-title
                class="font-weight-moderate color--text text--darken-3"
              >
                Dark Theme
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title
                class="font-weight-moderate color--text text--darken-3"
              >
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-navigation-drawer>

    <v-card class="borderify-bottom main-nav" outlined>
      <v-container fluid>
        <v-toolbar class="toolbar" flat>
          <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = !drawer" />
          <v-spacer v-if="$vuetify.breakpoint.xs" />
          <!-- <v-toolbar-title> -->
          <v-img
            :src="
              $img(`icons/${$vuetify.theme.dark ? 'light' : 'blue'}Horizontal.svg`)
            "
            alt="CodeWizards Logo"
            class="toolbar-logo"
            height="47"
            lazy-src="vuetify-loader"
            max-width="160"
            min-width="160"
            width="150"
            @click="logoClick"
          ></v-img>
          <!-- </v-toolbar-title> -->

          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

          <v-progress-circular v-if="!account.id" color="primary" indeterminate />
          <NavTabs v-else />

          <v-divider class="mx-4" vertical />
          <v-progress-circular v-if="!account.id" color="primary" indeterminate />
          <NavButtons
            v-else
            :is-loading="isLoading"
            @go-to-meeting="gotomeeting"
            @begin-student-feedback="beginStudentFeedback"
          />
        </v-toolbar>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import NavButtons from '@/components/NavBar/NavButtons.vue'
import NavTabs from '@/components/NavBar/NavTabs.vue'
import SuccessInfoErrorCard from '@/components/SuccessInfoErrorCard.vue'
import tabs from '@/components/NavBar/tabs'

import { mixpanel } from '@/utils/helperFunctions'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useAccountStore } from '@/stores/accountStore'
import { useNotificationsStore } from '@/stores/notificationsStore'
import { useModalStore } from '@/stores/modalStore'

export default {
  name: 'NavBar',
  components: {
    NavButtons,
    NavTabs,
    SuccessInfoErrorCard,
  },
  data() {
    return {
      tabs,
      isLoading: { zoomMeeting: false },
      isTabOpen: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      showChangeAvatarDialog: false,
      showChangeTitleDialog: false,
      showMeetingErrorCard: false,
      classURL: null,
      selectedItem: 0,
      drawer: false,
      joinClassModal: false,
      simulData: [],
      zoomClasses: [],
    }
  },
  computed: {
    // ...mapState('accountManagement', ['avatarDialog', 'titleDialog']),
    ...mapState(useAccountStore, ['account']),
    ...mapState(useNotificationsStore, ['notifications']),
    ...mapWritableState(useModalStore, ['surveyReminderModal']),
  },
  created() {
    this.darkTheme = this.$vuetify.theme.dark
  },
  methods: {
    ...mapActions(useAccountStore, ['logout']),
    beginStudentFeedback() {
      this.$router.push('/feedback')
    },
    logoClick() {
      this.$mixpanel('Click Logo')
      this.$router.push('/')
    },

    switchDarkTheme() {
      localStorage.setItem('darkTheme', !this.$vuetify.theme.dark)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.darkTheme = this.$vuetify.theme.dark
    },
    gotomeeting() {
      this.isLoading.zoomMeeting = true
      this.$http
        .get('/dashboard/join-class')
        .then((res) => {
          console.log('class zoom meeting res is: ', res)

          this.zoomClasses = res.data.classes

          if (res.data.classes.length === 1) {
            this.classURL = res.data.classes[0].zoom_url
            const newWindow = window.open(this.classURL, 'zoom_url')
          } else {
            this.joinClassModal = true
          }

          mixpanel('Join Class', {
            'Class Name': this.account.classname,
            'Class ID': this.account.class,
          })

          // attempts to determine is newWindow was able to open
          if (newWindow == null) {
            this.showMeetingErrorCard = true
          }
        })
        .catch((err) => {
          const { response } = err
          alert(response.data.detail)
        })
        .finally(() => (this.isLoading.zoomMeeting = false))
    },
    mixpanelMenuSections(tabName, menuName) {
      this.$mixpanel('Click Menu Sections', {
        section: tabName,
        parentSection: menuName,
        type: 'Mobile Width',
      })
    },
    mixpanelTopLevel(tabName) {
      this.$mixpanel('Top Level Navigation', {
        section: tabName,
        type: 'Mobile Width',
      })

      if (tabName === 'Documentation') {
        this.$mixpanel('Navigate to Documentation', { fromLocation: 'Navigation' })
      }
    },
    handleClickCompleteSurvey() {
      this.surveyReminderModal.show = false
      this.notifications.forEach((notfications) => {
        if (notfications.type === 4 && !notfications.dismissed) {
          this.beginStudentFeedback()
          return
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  background-color: #a5a5a5 !important;
}

.borderify-bottom {
  border-bottom: 1px solid var(--v-background-lighten4) !important;
  border-radius: 5px 5px 0 0;
}

.toolbar-logo {
  &:hover {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.theme--light.v-menu__content {
  background-color: white;
}
.theme--dark.v-menu__content {
  background-color: #1a1b1f;
}
</style>
