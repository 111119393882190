<template>
  <v-snackbar
    :color="alert.color"
    :multi-line="alert.multiline"
    :right="true"
    :timeout="alert.timeout"
    :top="true"
    v-model="alert.active"
  >
    {{ alert.message }}
    <v-btn @click="alert.active = false" dark icon text>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { useAccountStore } from '@/stores/accountStore'
import { mapState } from 'pinia'
export default {
  name: 'Alert',
  computed: { ...mapState(useAccountStore, ['alert']) },
}
</script>
